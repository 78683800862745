/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { getApp } from 'embercom/lib/container-lookup';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import containerLookup from 'embercom/lib/container-lookup';
import { isPresent } from '@ember/utils';
import type Survey from 'embercom/models/surveys/survey';
import type Question from 'embercom/models/surveys/question';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type Metric from 'embercom/objects/reporting/unified/metrics/types';
import { type RatioMetric } from 'embercom/objects/reporting/unified/metrics/types';
import { type Filter, type LogicalFilter } from 'embercom/components/reporting/custom/filters';

export const TEAMMATE_ALIAS = {
  teammate_assignee_at_close: 'teammate.id',
  teammate: 'teammate_id',
};
const TYPE_FILTER_COMPONENT_MAP = {
  'has-calls-filter': 'reporting/custom/chart-builder/filter-bar/has-calls-filter',
  'call-type-filter': 'reporting/custom/chart-builder/filter-bar/call-type-filter',
  'call-direction-filter': 'reporting/custom/chart-builder/filter-bar/call-direction-filter',
  'call-state': 'reporting/custom/chart-builder/filter-bar/call-state-filter',
  boolean: 'reporting/custom/chart-builder/filter-bar/custom-attributes/boolean-filter',
  'date-range-filter': 'reporting/custom/chart-builder/filter-bar/date-filter-block',
  continent: 'reporting/custom/chart-builder/filter-bar/continent-filter',
  country: 'reporting/custom/chart-builder/filter-bar/country-filter',
  'channel-filter': 'reporting/custom/chart-builder/filter-bar/channel-filter',
  'started-by-user': 'reporting/custom/chart-builder/filter-bar/started-by-user-filter',
  'teammate-participated': 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  'teammate-assigned': 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  'team-assigned': 'reporting/custom/chart-builder/filter-bar/team-assigned-filter',
  sla: 'reporting/custom/chart-builder/filter-bar/sla-filter',
  'sla-target-type': 'reporting/custom/chart-builder/filter-bar/sla-target-type-filter',
  tag: 'reporting/custom/chart-builder/filter-bar/tag-filter',
  'user-type': 'reporting/custom/chart-builder/filter-bar/user-type-filter',
  'conversation-rating': 'reporting/custom/chart-builder/filter-bar/conversation-rating-filter',
  'teammate-replied': 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  topic: 'reporting/custom/chart-builder/filter-bar/topic-filter',
  'conversation-state': 'reporting/custom/chart-builder/filter-bar/conversation-state-filter',
  'response-time-range': 'reporting/custom/chart-builder/filter-bar/response-time-range-filter',
  'companies.company_id':
    'reporting/custom/chart-builder/filter-bar/custom-attributes/string-filter',
  'companies.name': 'reporting/custom/chart-builder/filter-bar/custom-attributes/string-filter',
  'companies.size': 'reporting/custom/chart-builder/filter-bar/custom-attributes/string-filter',
  'companies.plan_id': 'reporting/custom/chart-builder/filter-bar/custom-attributes/string-filter',
  'companies.manual_tag_ids': 'reporting/custom/chart-builder/filter-bar/tag-filter',
  ticket: 'reporting/custom/chart-builder/filter-bar/ticket-type-filter',
  'ticket-state-category': 'reporting/custom/chart-builder/filter-bar/ticket-state-category-filter',
  'ticket-custom-state': 'reporting/custom/chart-builder/filter-bar/ticket-custom-state-filter',
  string: 'reporting/custom/chart-builder/filter-bar/custom-attributes/string-filter',
  'conversation_rating.admin_id': 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  comment_admin_assignee_id: 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  teammate_handled: 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  first_reply_teammate_id: 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  attributed_to_admin_id: 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  first_human_authored_closer_teammate_id:
    'reporting/custom/chart-builder/filter-bar/teammate-filter',
  last_human_authored_closer_teammate_id:
    'reporting/custom/chart-builder/filter-bar/teammate-filter',
  teammate_closed: 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  teammate_created_note: 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  mentions: 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  teammate_status: 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  teammate: 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  teammate_assignee_at_close: 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  teammate_participated: 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  'fin-last-sent-answer-type': 'reporting/custom/chart-builder/filter-bar/fin-last-answer-filter',
  fin_participated: 'reporting/custom/chart-builder/filter-bar/fin-participated-filter',
  answer_to_question:
    'reporting/custom/chart-builder/filter-bar/answer-to-question/answer-to-question-filter',
  bot_conversation_rating: 'reporting/custom/chart-builder/filter-bar/conversation-rating-filter',
  'teammate-call-participants': 'reporting/custom/chart-builder/filter-bar/teammate-filter',
  company_name: 'reporting/custom/chart-builder/filter-bar/company-name-filter',
  company_size: 'reporting/custom/chart-builder/filter-bar/company-size-filter',
  team: 'reporting/custom/chart-builder/filter-bar/team-assigned-filter',
  'regex-string': 'reporting/custom/chart-builder/filter-bar/custom-attributes/regex-string-filter',
  workflow: 'reporting/custom/chart-builder/filter-bar/workflow-name-filter',
  'rated-actor-type': 'reporting/custom/chart-builder/filter-bar/agent-type-filter',
  agent_type: 'reporting/custom/chart-builder/filter-bar/agent-type-filter',
  'rating-participation-group':
    'reporting/custom/chart-builder/filter-bar/rating-participation-group-filter',
  number: 'reporting/custom/chart-builder/filter-bar/custom-attributes/number-filter',
};

const DATA_TYPE_FILTER_COMPONENT_MAP = {
  guideline_ids: 'reporting/custom/chart-builder/filter-bar/mapped-attribute-filter',
  knowledge_hub_content_reference:
    'reporting/custom/chart-builder/filter-bar/mapped-attribute-filter',
};

export const TAG_FILTER_KEYS = ['scopingTagIds', 'userTags'];

export const USER_TYPE_FILTER_IDENTIFIER = 'conversation.user.role';
export const ANSWER_TO_QUESTION_IDENTIFIER = 'stats.question#';
export const DYNAMIC_FILTER_TYPES = [ANSWER_TO_QUESTION_IDENTIFIER];
export const TIME_FILTER_IDENTIFIER = 'time';

export const propertyIdentifierPrefixes = {
  customUserAttribute: 'user.custom_data',
  customConversationAttribute: 'conversation_custom_fields',
  customCompanyAttribute: 'companies.custom_data',
};
const propertyIdentifierSeparator = '#';

export function getPrefixAndIdFromCustomAttributeFilterIdentifier(identifier: string) {
  return identifier.split(propertyIdentifierSeparator);
}

export function surveyPropertyIdentifiers(survey: Survey) {
  let questionIdentifiers = survey.questions.map(
    (question: Question) => `${ANSWER_TO_QUESTION_IDENTIFIER}${question.id}`,
  );
  return [ANSWER_TO_QUESTION_IDENTIFIER, ...questionIdentifiers];
}

function findCustomAttribute(identifier: string) {
  let [prefix, id] = getPrefixAndIdFromCustomAttributeFilterIdentifier(identifier);
  if (notUserOrCompanyCustomDataPrefix(prefix) || !id) {
    return null;
  }
  let attribute = getApp().attributes.find((a: any) => String(a.cda_id) === id);
  if (attribute) {
    return attribute;
  }
  console.error(`Custom attribute ${identifier} is not available`);
  return { humanFriendlyName: 'Archived attribute' };
}

function notUserOrCompanyCustomDataPrefix(prefix: string) {
  return (
    prefix !== propertyIdentifierPrefixes.customUserAttribute &&
    prefix !== propertyIdentifierPrefixes.customCompanyAttribute
  );
}

export function getNameFromFilterIdentifierFromMetric(
  identifier: string,
  metric: Metric,
): string | undefined {
  let sharedAttributeName = _getNameFromSharedFilterIdentifier(identifier);
  if (sharedAttributeName) {
    return sharedAttributeName;
  }

  let metricAttribute = _getMetricAttributeFromIdentifier(identifier, metric);
  if (metricAttribute) {
    return metricAttribute;
  }

  return undefined;
}

export function getNameFromFilterIdentifier(
  identifier: string,
  renderableChart: RenderableChart,
): string | undefined {
  let sharedAttributeName = _getNameFromSharedFilterIdentifier(identifier);
  if (sharedAttributeName) {
    return sharedAttributeName;
  }
  let metric = renderableChart.metric(0) as Metric;
  let metricAttribute = _getMetricAttributeFromIdentifier(identifier, metric);
  if (metricAttribute) {
    return metricAttribute;
  }

  return undefined;
}

function _getNameFromSharedFilterIdentifier(identifier: string) {
  let descriptor = ConversationAttributeDescriptor.peekByIdentifier(identifier);
  if (descriptor) {
    return descriptor.name;
  }
  let attribute = findCustomAttribute(identifier);
  if (attribute) {
    return attribute.humanFriendlyName;
  }

  let intl = containerLookup('service:intl');
  if (['team', 'teammate'].includes(identifier)) {
    let reportingMetrics = containerLookup('service:reportingMetrics');
    let teamOrTeammateProperty = reportingMetrics.getPropertyById(identifier);
    if (teamOrTeammateProperty) {
      return intl.exists(teamOrTeammateProperty.name)
        ? intl.t(teamOrTeammateProperty.name)
        : teamOrTeammateProperty.name;
    }
    return identifier;
  }

  return undefined;
}

function _getMetricAttributeFromIdentifier(identifier: string, metric: Metric) {
  let intl = containerLookup('service:intl');
  let metricProperty = metric.supportedMetricProperties.find(({ field }) => field === identifier);
  if (metricProperty) {
    return intl.exists(metricProperty.name) ? intl.t(metricProperty.name) : metricProperty.name;
  } else if (metric.type === 'ratio') {
    let ratioProperty = (metric as RatioMetric).numerator.supportedMetricProperties.find(
      ({ field }) => field === identifier,
    );
    if (ratioProperty) {
      return intl.exists(ratioProperty.name) ? intl.t(ratioProperty.name) : ratioProperty.name;
    } else {
      return undefined;
    }
  } else if (Object.keys(TEAMMATE_ALIAS).includes(identifier)) {
    let metricProperty = metric.supportedMetricProperties.find(
      ({ field }) => field === TEAMMATE_ALIAS[identifier as keyof typeof TEAMMATE_ALIAS],
    );
    return intl.exists(metricProperty!.name) ? intl.t(metricProperty!.name) : metricProperty!.name;
  } else {
    return undefined;
  }
}

export function isDateCDA(identifier: string) {
  if (!identifier) {
    return false;
  }

  let descriptor = ConversationAttributeDescriptor.peekByIdentifier(identifier);
  if (descriptor) {
    return descriptor.dataType === 'datetime';
  }

  let attribute = findCustomAttribute(identifier);
  if (attribute) {
    return attribute.type === 'date';
  }

  return false;
}

function isExistOrNotExistFilter(filter: Filter) {
  return filter.type && filter.type.endsWith('exists');
}

export function humanizeFilters(filters: LogicalFilter, renderableChart: RenderableChart) {
  if (!filters.filters) {
    return [];
  }

  return filters.filters.reduce((acc: string[], f) => {
    if (isExistOrNotExistFilter(f) || f.data.values?.length) {
      let name = getNameFromFilterIdentifier(f.data.property, renderableChart);
      if (name !== undefined) {
        acc.push(name.toLowerCase());
      }
    }
    return acc;
  }, []);
}

export function humanizeFiltersForMetric(filters: LogicalFilter, metric: Metric) {
  if (!filters.filters) {
    return [];
  }

  return filters.filters.reduce((acc: string[], f) => {
    if (isExistOrNotExistFilter(f) || f.data.values?.length) {
      let name = getNameFromFilterIdentifierFromMetric(f.data.property, metric);
      if (name !== undefined) {
        acc.push(name.toLowerCase());
      }
    }
    return acc;
  }, []);
}

export function attributesForStandalone() {
  return [
    'conversation.id',
    'conversation.first_user_conversation_part_created_at',
    'conversation.user.location.continent_code',
    'conversation.user.location.country_code',
    'conversation.channel_type',
    'conversation.conversation_state',
    'conversation_custom_fields#standalone_zendesk_brand',
  ];
}

export function filterableAttributesForStandalone() {
  return [
    'time',
    'conversation.user.location.continent_code',
    'conversation.user.location.country_code',
    'conversation.channel_type',
    'conversation.conversation_state',
    'conversation_custom_fields#standalone_zendesk_brand',
  ];
}

export function filterableCDAAttributesGroupsForStandalone() {
  return ['conversation_custom_fields', 'user.custom_data'];
}

export function filterableProperties() {
  let properties = [
    'time',
    'conversation.admin_assignee_id',
    'conversation.team_assignee_id',
    'conversation.admin_participant_ids',
    'conversation.user.location.continent_code',
    'conversation.user.location.country_code',
    'conversation.channel_type',
    'conversation.conversation_tag_ids',
    'conversation.user.role',
    'conversation.user.manual_tag_ids',
    'conversation.conversation_rating.rating_index',
    'conversation.bot_conversation_rating.rating_index',
    'conversation.topic',
    'conversation.conversation_state',
    'conversation.conversation_started_by_user',
    'conversation.companies.company_id',
    'conversation.companies.name',
    'conversation.companies.size',
    'conversation.companies.plan_id',
    'conversation.companies.manual_tag_ids',
    'conversation.fin.participated',
    'conversation.first_response_time',
    'conversation.first_response_time_excluding_bot_inbox',
    'conversation.first_response_time_in_office_hours',
    'conversation.first_response_time_excluding_bot_inbox_in_office_hours',
    'conversation.conversation_rating.admin_id',
    'conversation.ticket_type_id',
    'conversation_rating_sent.rated_actor_id.human_id',
    'conversation_rating_sent.rated_actor_id.workflow_id',
    'conversation_rating_sent.rating_index',
    'conversation_rating_sent.rated_actor_type',
    'conversation_sla_status_log.conversation_sla_id',
    'tickets.current_ticket_custom_state_id',
    'tickets.current_ticket_state_category',
    'call.direction',
    'call.call_type',
  ];

  if (getApp().canSeeChatbotCsatReporting) {
    properties.push(
      'conversation.workflow_conversation_rating.workflow_id',
      'conversation.workflow_conversation_rating.rating_index',
    );
  }

  if (getApp().canUseCSATVOne) {
    properties.push('conversation_rating_sent.rating_participants.human_ids');
    properties.push('conversation_rating_sent.rating_participants.workflow_ids');
    properties.push('conversation_rating_sent.rating_participants.fin');
    properties.push('conversation_rating_sent.rating_participation_group');
  }

  if (getApp().canUseFinGuidanceGA) {
    properties.push('conversation.fin.guidelines_applied.guideline_ids');
  }

  return properties.compact();
}

export function isDefaultValue(value: any) {
  return value === REPORTING_FILTER_SELECT_ALL;
}

export function filterComponentForAttributeType(attributeType: string, dataType: string) {
  if (dataType in DATA_TYPE_FILTER_COMPONENT_MAP) {
    return DATA_TYPE_FILTER_COMPONENT_MAP[dataType as keyof typeof DATA_TYPE_FILTER_COMPONENT_MAP];
  } else if (attributeType in TYPE_FILTER_COMPONENT_MAP) {
    return TYPE_FILTER_COMPONENT_MAP[attributeType as keyof typeof TYPE_FILTER_COMPONENT_MAP];
  }
  return null;
}

export const FilterLogicalOperators = {
  and: 'and',
  or: 'or',
};

export function changeFilter(
  filter: LogicalFilter,
  index: number,
  attribute: { id: string; field: string },
  values: string[],
  operator: string | null,
): LogicalFilter {
  if (attribute.id === 'time') {
    return filter;
  }

  // toArray forces a new array instance that ensures ember's dirty state tracking catches the change
  let filters = filter.filters?.toArray() || [];
  let existingFilter = filters[index];
  let topLevelFilterType = filter.type || FilterLogicalOperators.and;

  if (operator === null && values.length === 0) {
    // remove a filter
    filters = filters.reject((_f: any, filterIndex: number) => index === filterIndex);
  } else if (isPresent(existingFilter)) {
    // update a filter
    filters.splice(index, 1, _filterFrom(attribute, values, operator));
  } else {
    // add a filter
    filters.push(_filterFrom(attribute, values, operator));
  }

  return {
    type: topLevelFilterType,
    filters,
  };
}

function _filterFrom(
  attribute: { id: string; field: string },
  values: string[],
  operator: string | null,
) {
  let filter: Filter = {
    type: operator!,
    data: {
      property: attribute.field,
      attribute: attribute.id,
    },
  };
  if (isPresent(values)) {
    filter.data.values = values;
  }
  return filter;
}
